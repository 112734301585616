<template>
  <v-dialog v-model="dialog" scrollable max-width="1000">
    <dialog-card title="Pontos de Impactação" @close="close">
      <v-list>
        <v-list-item
          v-for="(ip, index) in impactionPoints"
          :key="index"
          class="border-bottom-grey"
          @click="selectImpactionPoint(ip)"
        >
          <v-list-item-content>
            <span class="subtitle-1">
              {{ selectedTrackingPoint.name }} - {{ ip.name }}
            </span>
            <v-list-item-subtitle>
              Associações Patogênicas: {{ ip.categories }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>open_in_new</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div class="text-center mt-5">
        <v-btn large outlined color="secondary" @click="close">
          <v-icon>check</v-icon>
          Concluir
        </v-btn>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    diagnosePar: {},
    expanded: [],
  }),
  computed: {
    impactionPoints() {
      return this.$store.state.checkCategories.impactionPoints;
    },
    selectedTrackingPoint() {
      return this.$store.state.checkCategories.selectedTrackingPoint;
    },
    selectedImpactionPoint() {
      return this.$store.state.checkCategories.selectedImpactionPoint;
    },
    selectedPar() {
      return this.$store.state.checkCategories.selectedPar;
    },
    dialog: {
      get() {
        return this.$store.state.dialogs.checkCategories.impactionPoints;
      },
      set(value) {
        this.$store.commit("dialogs/setCheckCategoriesImpactionPoints", value);
      },
    },
    selectedType: {
      get() {
        return this.$store.state.checkCategories.selectedType;
      },
    },
  },
  methods: {
    selectImpactionPoint(impactionPoint) {
      this.$store.commit(
        "checkCategories/setSelectedImpactionPoint",
        impactionPoint
      );
      this.$store.dispatch("checkCategories/findParAndOpenParDialog");
    },
    async addToDiagnose() {
      await this.$store.dispatch(
        "diagnosis/validateIfExistsParInDiagnosis",
        this.selectedPar.id
      );

      const { therapistComments, patientComments } = await this.$store.dispatch(
        "settings/openComments"
      );
      Object.assign(this.diagnosePar, { therapistComments, patientComments });
      const diagnosePar = this.prepareObjectToDiagnosePar();
      await this.$store.dispatch("diagnosis/addParToDiagnose", diagnosePar);
      this.$toasted.global.success({
        message: "Par adicionado ao diagnóstico.",
      });
      this.dialog = false;
    },
    prepareObjectToDiagnosePar() {
      return {
        ...this.diagnosePar,
        par: {
          ...this.selectedPar,
          points: {
            trackingPoint: this.selectedTrackingPoint,
            impactionPoint: this.selectedImpactionPoint,
          },
        },
      };
    },
    async close() {
      console.log("close");

      if (this.selectedType) {
        const ids = await this.$store.state[
          this.selectedType
        ].selected.categories.map((category) => category.id);
        this.$store.dispatch(
          "checkCategories/startByIds",
          { ids, selectedType: this.selectedType },
          { root: true }
        );
      }

      this.dialog = false;
    },
  },
};
</script>

<style></style>
